@keyframes decor {
  from {
    height: 10%;
  }
  to {
    height: 100%;
  }
}

.decor {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  height: 40px;
  position: relative;
}

.decor-stats {
  position: absolute;
  font-weight: 600;
  font-size: 1rem;
  text-shadow: 0 0 2px white;
  left: 6px;
  top: 0px;
  z-index: 1;
}

.decor-bordered {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background-color: white;
  height: 80px;
  width: 100%;
  margin: auto;
  min-width: 140px;
}

.decor-with-labels {
  border-bottom-width: 2rem;
}

.decor-item {
  height: 10%;
  width: 8px;
  animation: decor 0.7s ease-out infinite alternate;
  box-shadow: 1px 1px 3px rgba(0 0 0 / 20%);
}

.decor-with-labels .decor-item-chart {
  box-shadow: none;
}

.decor-item-chart {
  height: 0%;
  width: 14.285%;
  position: relative;
  box-shadow: -1px -1px 3px rgba(0 0 0 / 20%);
  transition: box-shadow 1s ease-out;
}

.decor-item-chart-class {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.8rem;
  font-size: 0.9rem;
  font-weight: 600;
  text-shadow: 0 0 2px white;
}
